import React from 'react';
import styled from 'styled-components';
import Poll1 from 'assets/images/docs/poll1.png';
import Poll2 from 'assets/images/docs/poll2.png';
import Poll5 from 'assets/images/docs/poll5.png';

const Wrapper = styled.div`
  align-items: center;
  color: #004d9a;
  display: flex;
  flex-direction: column;
  grid-column: 1;
  justify-content: center;
  margin-top: 7.5rem;
  overflow-x: hidden;
  width: 100%;

  h2 {
    margin: 0.5rem;
    padding: 0.5rem;
  }
  h3 {
    font-weight: normal;
    text-align: center;
    width: 50vw;
  }
  @media only screen and (max-width: 1024px) {
    h3 {
      width: 90vw;
    }
  }
`;

const Image = styled.img`
  width: 100%;
`;

const Option = styled.div`
  display: flex;
  margin: 1rem 0 0 0;
  @media only screen and (max-width: 520px) {
    flex-direction: column;
  }
`;

const ImageCaption = styled.figcaption`
  padding: 1rem 0 2rem 0;
  width: 100%;
`;

const Figure = styled.figure`
  align-items: center;
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem;
  width: 50vw;
  @media only screen and (max-width: 1024px) {
    width: 90vw;
  }
`;

const FigureWrapper = styled.div`
  margin: 3rem;
  figure {
    margin: 2rem 0;
  }
`;

const PollDocumentation = () => (
  <Wrapper>
    <h2>Polls Guidelines</h2>
    <h3>
      As a moderator you have access to the Moderator&apos;s Control Panel from within the Polls
      feature of the livestream event. Here you can create, edit and open polls that encourage
      participation from viewers. You can then view or share the results with participants.
    </h3>
    <FigureWrapper>
      <Figure>
        <Image src={Poll1} />
        <ImageCaption style={{ textAlign: 'center' }}>
          To create a new poll select <strong>CREATE POLL</strong>.
        </ImageCaption>
      </Figure>
      <Figure>
        <Image src={Poll2} />
        <ImageCaption>
          Fill in all necessary fields. You must provide a question and two or more answers for
          participants to choose from. If applicable, choose one or more options from the checklist
          to add additional settings to the poll.
          <br />
          <br />
          To save these settings, select <strong>SAVE</strong>
        </ImageCaption>
      </Figure>
      <h4>Choosing Additional Settings</h4>
      <Figure>
        <Option>
          <ImageCaption style={{ margin: '0 1rem' }}>
            <strong>Marking correct answer/s</strong>
            <br />
            <br />
            If the question has a predefined answer/s, choose either one or more correct answers
            from the options you provided.
          </ImageCaption>
          <iframe
            title="vimeo-player"
            src="https://player.vimeo.com/video/641092835?autoplay=1&loop=1&autopause=0&title=0&portrait=0&byline=0"
            height="340"
            frameBorder="0"
            allow="autoplay"
          />
        </Option>
        <Option>
          <ImageCaption style={{ margin: '0 1rem' }}>
            <strong>Allow multiple choice</strong>
            <br />
            <br />
            Allows participants to select one or more answers from the options you provided. If
            selected the default option allows participants to select all answers, you can change
            this setting by selecting the dropdown and choosing how many answers a partcipant can
            select.
          </ImageCaption>
          <iframe
            title="vimeo-player"
            src="https://player.vimeo.com/video/641089344?autoplay=1&loop=1&autopause=0&title=0&portrait=0&byline=0"
            height="340"
            frameBorder="0"
            allow="autoplay"
          />
        </Option>
        <Option>
          <ImageCaption style={{ margin: '0 1rem' }}>
            <strong>Enabling a timer</strong>
            <br />
            <br />
            Sets a timer for which participants can submit their answer/s. The poll will continue to
            display to viewers unless you choose to close the poll.
          </ImageCaption>
          <iframe
            title="vimeo-player"
            src="https://player.vimeo.com/video/641091850?autoplay=1&loop=1&autopause=0&title=0&portrait=0&byline=0"
            height="340"
            frameBorder="0"
            allow="autoplay"
          />
        </Option>
        <ImageCaption style={{ margin: '0 1rem' }}>
          <br />
          To save these settings, select <strong>SAVE</strong>.
        </ImageCaption>
      </Figure>
      <h4>Edit or Delete Polls</h4>
      <Figure>
        <Image src={Poll5} />
        <ImageCaption>
          Once saved, a poll can be found filed under Queued Polls.
          <br />
          <br />
          At anytime you can make changes to a poll you&apos;ve created. <strong>Edit</strong>{' '}
          allows you to make changes to poll settings. <strong>Delete</strong> allows you to
          permanently delete a poll, along with any corresponding analytics.
        </ImageCaption>
      </Figure>
      <h4>Opening a Poll to Viewers & Partcipants</h4>
      <Figure>
        <iframe
          title="vimeo-player"
          src="https://player.vimeo.com/video/641086685?autoplay=1&loop=1&autopause=0&title=0&portrait=0&byline=0"
          width="100%"
          height="260"
          frameBorder="0"
          allow="autoplay"
        />
        <ImageCaption>
          Choose a poll then select <strong>OPEN POLL</strong> to display and open to viewers and
          partcipants.
          <br />
          <br />
          Viewers will be presented with a question and answers to choose from. If a viewer decides
          to participate, they will choose their answer/s and select <strong>SUBMIT</strong>.
          <br />
          <br />
          When you a ready to close a poll select <strong>CLOSE POLL</strong>. Once a poll is closed
          it will then be filed under Closed Polls, analytics will be available to show how many
          viewers participated and how those participants answered.
        </ImageCaption>
      </Figure>
      <h4>Viewing & Sharing Poll Analytics</h4>
      <Figure>
        <iframe
          title="vimeo-player"
          src="https://player.vimeo.com/video/641088106?autoplay=1&loop=1&autopause=0&title=0&portrait=0&byline=0"
          width="100%"
          height="260"
          frameBorder="0"
          allow="autoplay"
        />
        <ImageCaption>
          You can choose to view poll analytics privately and/or share these analytics with viewers.
          Select <strong>View Analytics</strong> to see a breakdown of the poll analytics in the
          control panel. Select <strong>SHARE ANALYTICS</strong> to share a breakdown of the poll
          analytics with viewers and participants.
          <br />
          <br />
          To stop sharing poll analytics with viewers, select <strong>STOP SHARING</strong>.
        </ImageCaption>
      </Figure>
    </FigureWrapper>
  </Wrapper>
);

export default PollDocumentation;
